import React, { useEffect, useState, useContext} from 'react';
import './SvgConverter.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
//import {products} from './ProductsSizeData';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ButtonGroup from '@mui/material/ButtonGroup'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios';
import { parse } from 'jsonc-parser';

const SvgConverter = () => {
  
  const [text, setText] = useState('');
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [textArea, settextArea] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [textHeight1, setTextHeight1] = useState('');
  const [textHeight2, setTextHeight2] = useState('');
  const [textHeight3, setTextHeight3] = useState('');
  const [textHeight4, setTextHeight4] = useState('');
  const [rectangleX, setRectangleX] = useState('');
  const [rectangleY, setRectangleY] = useState('');
  const [rectangleWidth, setRectangleWidth] = useState('');
  const [rectangleHeight, setRectangleHeight] = useState('');
  const [rectangleX2, setRectangleX2] = useState('');
  const [rectangleY2, setRectangleY2] = useState('');
  const [rectangleWidth2, setRectangleWidth2] = useState('');
  const [rectangleHeight2, setRectangleHeight2] = useState('');
  const [rectangleX3, setRectangleX3] = useState('');
  const [rectangleY3, setRectangleY3] = useState('');
  const [rectangleWidth3, setRectangleWidth3] = useState('');
  const [rectangleHeight3, setRectangleHeight3] = useState('');
  const [rectangleX4, setRectangleX4] = useState('');
  const [rectangleY4, setRectangleY4] = useState('');
  const [rectangleWidth4, setRectangleWidth4] = useState('');
  const [rectangleHeight4, setRectangleHeight4] = useState('');
  const [fontFamily, setfontFamily] = useState('');
  const [fontFamily2, setfontFamily2] = useState('');
  const [fontFamily3, setfontFamily3] = useState('');
  const [fontFamily4, setfontFamily4] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [text1Visible, setText1Visible] = useState(false);
  const [textAreaVisible, settextAreaVisible] = useState(false);
  const [text2Visible, setText2Visible] = useState(false);
  const [text3Visible, setText3Visible] = useState(false);
  const [text4Visible, setText4Visible] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  const [selectedAligment, setselectedAligment] = useState('left');
  const [fontStatus, setFontStatus] = useState('false');
  const [designType, setDesignType] = useState('');
  const [lineCounts, setLineCounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState('processed-image.svg');
  const [lineHeight, setLineHeight] = useState('55');
  const [rotationAngle, setRotationAngle] = useState(0);
  let hoveredAccordion = null;
  const [hoverImgVisible, setHoverImgVisible] = useState(false);
  const [hoverImgUrl,setHoverImgUrl] = useState('');
  const PathContext = React.createContext([]);
  const [shopData, setShopData] = useState([]);
  const [presentPath, setPresentPath] = useState('');
  

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const response = await axios.get('/shopDataJson.jsonc'); // JSONC dosyasının URL'sini buraya ekleyin
        const jsoncText = response.data;
        const parsedData = parse(jsoncText); // JSONC formatını JSON'a dönüştürüyoruz
        setShopData(parsedData); // JSON içindeki shops verisini alıyoruz
       
      } catch (error) {
       // console.error('Error fetching shop data:', error);
      }
    };

    fetchShopData();
  }, []);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleOptionChangeFont = (event) => {
    setfontFamily(event.target.value);
  };

  const CustomButton = styled(Button)({
    borderColor: 'black',
    color: 'black',
    '&:hover': {
      borderColor: 'rgba(121, 147, 81)',
      backgroundColor: 'rgba(122, 178, 178, 0.1)',
    },
  });
  
  const [svgContent, setSvgContent] = useState('');
  const [svgExample, setSvgExample] = useState('');

  useEffect(() => {
    setSvgExample('');
    fetch(imageUrl)
      .then((response) => response.text())
      .then((data) => setSvgContent(data));
      console.log(imageUrl);
  }, [imageUrl]);

  
  const handleChange = (event) => {
    setFontStatus('false');
    const { id, value } = event.target;
    if (id === 'textOne') {
      setText(value);
    } else if (id === 'textTwo') {
      setText1(value);
    } else if (id === 'textThree') {
      setText2(value);
    }
    else if (id === 'textFour'){
      setText3(value);
    }
    else if(id === 'textArea'){
      settextArea(value);
      setFontStatus('true');

      const lines = value.split('\n');
      const counts = lines.map(line => line.length);
      setLineCounts(counts);
    }
    else if(id === "outlined-number"){
      setLineHeight(value);
      setFontStatus('true');
    }
  };

  const rects = [
    { x: rectangleX, y: rectangleY, width: rectangleWidth, height: rectangleHeight, text1: text || textArea , textHeight1: textHeight1,fontFamily:fontFamily },
    { x: rectangleX2, y: rectangleY2, width: rectangleWidth2, height: rectangleHeight2, text2 : text1, textHeight2: textHeight2,fontFamily:fontFamily2 || fontFamily },
    { x: rectangleX3, y: rectangleY3, width: rectangleWidth3, height: rectangleHeight3, text3 : text2, textHeight3: textHeight3,fontFamily:fontFamily3 || fontFamily },
    { x: rectangleX4, y: rectangleY4, width: rectangleWidth4, height: rectangleHeight4, text4 : text3, textHeight4: textHeight4,fontFamily:fontFamily4 || fontFamily },
  ];

  const downloadSVG = async () => {
    try {
      const processedSVG = await processSVG(false); // processSVG fonksiyonunuza göre özelleştirin
      const blob = await processedSVG.blob();

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      handleClose(); // İndirme tamamlandıktan sonra dialogu kapat
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const trySVG = async () => {
    console.log(fontFamily2);
    try {
      setSvgExample('');
      
      const processedSVG = await processSVG(true);
      const data = await processedSVG.text(); // Use .text() instead of .blob()
      
      setSvgExample(data);
    } catch (error) {
      console.error('Error fetching SVG:', error);
    }
  };
  
  const processSVG = async (test) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, 'image/svg+xml');
    
    const textElement = doc.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.textContent = text;
    doc.documentElement.appendChild(textElement);
    
    const serializedSVG = new XMLSerializer().serializeToString(doc);
    
     return fetch('https://outlettee.com:3003/process-svg', {
    //   return fetch('http://localhost:3003/process-svg', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: text, svg: serializedSVG, rects: rects, fontFamily: fontFamily, fontStatus: fontStatus, alignment:selectedAligment, designType:designType, test: test
        ,lineHeight: lineHeight, rotationAngle:rotationAngle, dimensions:dimensions
      })
    });
  };



  const RecursiveAccordion = ({ data,parentPath = [] }) => {
    const [hoveredItem, setHoveredItem] = useState(null);
    const currentPath = useContext(PathContext);
  
    const handleMouseEnter = (name) => {
      setHoveredItem(name);
    };
  
    const handleMouseLeave = () => {
      setHoveredItem(null);
    };

    const changeImageUrl = (newImageUrl, height, width,x,y,
      height2, width2,x2,y2,height3, width3,x3,y3,
      textHeight1,textHeight2, textHeight3, fontFamily,text1Visible,text2Visible,
      text3Visible,textAreaVisible,designType,rotationAngle,fontFamily2,textHeight4, 
      height4, width4,x4,y4, text4Visible, fontFamily3, fontFamily4
    ) =>{
      setText('');
      setText1('');
      setText2('');
      setText3('');
      settextArea('');
      setImageUrl(newImageUrl);
      setRectangleX(x);
      setRectangleY(y);
      setRectangleWidth(width);
      setRectangleHeight(height);
      setRectangleX2(x2);
      setRectangleY2(y2);
      setRectangleWidth2(width2);
      setRectangleHeight2(height2);
      setRectangleX3(x3);
      setRectangleY3(y3);
      setRectangleWidth3(width3);
      setRectangleHeight3(height3);
      setTextHeight1(textHeight1);
      setTextHeight2(textHeight2);
      setTextHeight3(textHeight3);
      setfontFamily(fontFamily);
      setfontFamily2(fontFamily2);
      setText1Visible(text1Visible);
      setText2Visible(text2Visible);
      setText3Visible(text3Visible);
      settextAreaVisible(textAreaVisible);
      setDesignType(designType);
      setRotationAngle(rotationAngle);
      setTextHeight4(textHeight4);
      setRectangleX4(x4);
      setRectangleY4(y4);
      setRectangleWidth4(width4);
      setRectangleHeight4(height4);
      setText4Visible(text4Visible);
      setfontFamily3(fontFamily3);
      setfontFamily4(fontFamily4);
      const fullPath = [...currentPath, data.name];
      setPresentPath(fullPath.join(" > "));
    }
    const newPath = data.name ? [...currentPath, data.name] : currentPath;
    return (
      <PathContext.Provider value={newPath}>
      <Accordion
      defaultExpanded={data.autoOpen}
      style={{
        background: data.background,
        borderRadius: data.borderRadius,
        overflow: 'hidden',
        position: 'relative',
      }}
      onMouseEnter={() => handleMouseEnter(data.name)}
      onMouseLeave={handleMouseLeave}
    >
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography>{data.name}</Typography>
        {data.url && (
          <img src={data.url} alt="icon" style={{ width: 50, height: 50 }} />
        )}
      </AccordionSummary>
      <AccordionDetails style={{ maxHeight: data.maxHeight, overflow: 'auto', background: data.background }}>
        {data.details.map((detail, index) =>
          detail.subShops ? (
            <RecursiveAccordion
              key={index}
              data={{
                name: detail.name,
                details: detail.subShops,
                maxHeight: detail.maxHeight,
                autoOpen: detail.autoOpen,
                buttonText: detail.buttonText,
                background: detail.background,
                url: detail.url,
                hoverImageUrl: detail.hoverImageUrl,
              }}
            />
          ) : (
            <div key={index}>
              <Typography>{detail.description}</Typography>
              {detail.name === 'SubShop1Button' && (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  <Button 
                    onClick={() =>
                      changeImageUrl(
                        detail.url,
                        detail.height1,
                        detail.width1,
                        detail.x1,
                        detail.y1,
                        detail.height2,
                        detail.width2,
                        detail.x2,
                        detail.y2,
                        detail.height3,
                        detail.width3,
                        detail.x3,
                        detail.y3,
                        detail.textHeight1,
                        detail.textHeight2,
                        detail.textHeight3,
                        detail.fontFamily,
                        detail.text1Visible,
                        detail.text2Visible,
                        detail.text3Visible,
                        detail.textAreaVisible,
                        detail.designType,
                        detail.rotationAngle,
                        detail.fontFamily2,
                        detail.textHeight4,
                        detail.height4,
                        detail.width4,
                        detail.x4,
                        detail.y4,
                        detail.text4Visible,
                        detail.fontFamily3,
                        detail.fontFamily4,
                      )
                    }
                    variant="contained"
                    style={{
                      backgroundColor: '#f5f5dc',
                      color: '#000',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                      textTransform: 'none',
                      width: '-webkit-fill-available',
                    }}
                  >
                    {detail.url && detail.url.includes('EmptySvg') ? null : (
                      <img
                        src={detail.url}
                        alt="icon"
                        style={{ width: 50, height: 50, marginRight: '8px', borderRadius: '4px' }}
                      />
                    )}
                    {detail.buttonName}
                  </Button>
                </div>
              )}
            </div>
          )
        )}
      </AccordionDetails>
    </Accordion>
    </PathContext.Provider>
    );
  };
 
  const handleCheckboxChange = (event) => {
    setSelectedCheckbox(event.target.name);
  };

  const handleAlignmentChange = (newAlignment) => {
    setselectedAligment(newAlignment);
   
  };

  const handleButtonClick = (event) => {
  
    const buttonId = event.currentTarget.id;
    console.log(buttonId);
    if(selectedCheckbox === 'textOne'){
      if (buttonId === 'up') {
        setRectangleY(rectangleY - 5);
      } else if (buttonId === 'left') {
        setRectangleX(rectangleX - 5);
      } else if (buttonId === 'right') {
        setRectangleX(rectangleX + 5);
      } else if (buttonId === 'down') {
        setRectangleY(rectangleY + 5)
      }
    }
    else if(selectedCheckbox === 'textTwo'){
      if (buttonId === 'up') {
        setRectangleY2(rectangleY2 - 5);
      } else if (buttonId === 'left') {
        setRectangleX2(rectangleX2 - 5);
      } else if (buttonId === 'right') {
        setRectangleX2(rectangleX2 + 5);
      } else if (buttonId === 'down') {
        setRectangleY2(rectangleY2 + 5)
      }
    }
    else if(selectedCheckbox === 'textThree'){
      if (buttonId === 'up') {
        setRectangleY3(rectangleY3 - 5);
      } else if (buttonId === 'left') {
        setRectangleX3(rectangleX3 - 5);
      } else if (buttonId === 'right') {
        setRectangleX3(rectangleX3 + 5);
      } else if (buttonId === 'down') {
        setRectangleY3(rectangleY3 + 5)
      }
    }
    else if(selectedCheckbox === 'textFour'){
      if (buttonId === 'up') {
        setRectangleY4(rectangleY4 - 5);
      } else if (buttonId === 'left') {
        setRectangleX4(rectangleX4 - 5);
      } else if (buttonId === 'right') {
        setRectangleX4(rectangleX4 + 5);
      } else if (buttonId === 'down') {
        setRectangleY4(rectangleY4 + 5)
      }
    }
    trySVG();
  };
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedSide, setSelectedSide] = useState('');
  const [dimensions, setDimensions] = useState({ width: '', height: '' });

  const products = [
    {
      name: 'Men Wallet',
      engraveSides: [
        { side: 'Front', width: '3.5', height: '4.5' },
        { side: 'Inside Right', width: '1.4', height: '3.55' },
        { side: 'Inside Left', width: '1.4', height: '3.55' },
      ],
    },
    {
      name: 'Slim Wallet',
      engraveSides: [
        { side: 'Front', width: '2.5', height: '3' },
      ],
    },
    {
      name: 'Card Holder',
      engraveSides: [
        { side: 'Front', width: '2.75', height: '3.75' },
        { side: 'Inside Right', width: '2.3', height: '2.3' },
        { side: 'Inside Left', width: '2.3', height: '2.3' },
      ],
    },
    {
      name: 'Makeup Bag',
      engraveSides: [
        { side: 'Front', width: '4', height: '9.5' },
      ],
    },
    {
      name: 'Jewelry Box',
      engraveSides: [
        { side: 'Front', width: '3.5', height: '3.5' },
      ],
    },
    {
      name: 'Velvet Dog Collar',
      engraveSides: [
        { side: 'Front', width: '0.6', height: '1' },
      ],
    },
    {
      name: 'Leather Dog Collar',
      engraveSides: [
        { side: 'Front', width: '0.4', height: '1.5' },
      ],
    },
    {
      name: 'Dopp Kit L',
      engraveSides: [
        { side: 'Front', width: '3.75', height: '7.5' },
        { side: 'Back', width: '3.75', height: '7.5' },
      ],
    },
    {
      name: 'Dopp Kit XL',
      engraveSides: [
        { side: 'Front', width: '4', height: '9' },
        { side: 'Back', width: '4', height: '9' },
      ],
    },
    {
      name: 'Bookmark',
      engraveSides: [
        { side: 'Front', width: '1.2', height: '6' },
      ],
    },
    {
      name: 'Tray Storage',
      engraveSides: [
        { side: 'Front', width: '6', height: '6' },
      ],
    },
    {
      name: 'Ring Box',
      engraveSides: [
        { side: 'Front', width: '2', height: '2' },
      ],
    },
    {
      name: 'Guitar Pick',
      engraveSides: [
        { side: 'Case', width: '1.75', height: '1.75' },
        { side: 'Pick', width: '0.75', height: '1' },
      ],
    },
    {
      name: 'Cutting Board M',
      engraveSides: [
        { side: 'Front', width: '7', height: '10' },
      ],
    },
    {
      name: 'Cutting Board L',
      engraveSides: [
        { side: 'Front', width: '9', height: '13.75' },
      ],
    },
    {
      name: 'Knifes',
      engraveSides: [
        { side: 'Handle', width: '6', height: '6' },
        { side: 'Sharp', width: '4', height: '4' },
      ],
    },
  ];
  

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
    setSelectedSide(''); // Seçim yapıldığında kazıma tarafını sıfırla
    setDimensions({ width: '', height: '' }); // Yeni ürün seçildiğinde boyutları sıfırla
  };

  const handleSideChange = (event) => {
    const selectedSide = event.target.value;
    setSelectedSide(selectedSide);
    const product = products.find((p) => p.name === selectedProduct);
    const engraveSide = product.engraveSides.find((s) => s.side === selectedSide);
    setDimensions({ width: engraveSide.width, height: engraveSide.height });
  };
  
  return (
    <div className="outer-container">
      <div className="container">
    
        <div className="layout-panel">
        <div>
        {shopData.map((shop, index) => (
        <RecursiveAccordion key={index} data={shop} />
        ))}
        </div>
        </div>
        <div className="left-panel">

        {hoverImgVisible === true ? (
          <img
            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            src={hoverImgUrl}
          />
        ) : null}

          <h2>Düzenleme Paneli</h2>
          <h3 style = {{color: "#705C53"}}> {presentPath}</h3>

          <FormControl fullWidth style={{ marginBottom: '20px', marginTop: '20px', width: '50%',marginLeft: '1%'  }}>
          <InputLabel style={{  width: '63%',marginLeft: '15%' }}>Product</InputLabel>
          <Select style={{  width: '63%',marginLeft: '15%' }} label = "Product" value={selectedProduct} onChange={handleProductChange}>
            {products.map((product) => (
              <MenuItem key={product.name} value={product.name}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedProduct && (
          <FormControl fullWidth style={{ marginBottom: '20px',width: '50%',marginLeft: '1%'  }}>
            <InputLabel style={{  width: '63%',marginLeft: '15%' }}>Engrave Side</InputLabel>
            <Select label="Engrave Side" value={selectedSide} onChange={handleSideChange} style={{ width: '63%',marginLeft: '15%' }}>
              {products
                .find((product) => product.name === selectedProduct)
                .engraveSides.map((side) => (
                  <MenuItem key={side.side} value={side.side}>
                    {side.side}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        {textAreaVisible && (
        <div tyle={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

            <TextField
            onChange={handleChange}
            value={lineHeight}
            style={{ marginBottom: '20px', width: '63%',marginLeft: '50px' }}
            id="outlined-number"
            label="Satır Aralığı"
            type="number"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
        
            }} 
          />
        </div>
      )}
        {text1Visible && (
        <div tyle={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        
          <TextField
            id="textOne"
            label="Metin Girin"
            variant="standard"
            value={text}
            onChange={handleChange}
            style={{ marginBottom: '20px', width: '80%' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckbox === 'textOne'}
                onChange={handleCheckboxChange}
                name="textOne"
          
              />
            }
           
          />
        </div>
      )}
      {text2Visible && (
        <div>
          <TextField
            id="textTwo"
            label="Metin Girin"
            variant="standard"
            value={text1}
            onChange={handleChange}
            style={{marginBottom: '20px', width: '80%'  }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckbox === 'textTwo'}
                onChange={handleCheckboxChange}
                name="textTwo"
              />
            }
       
          />
        </div>
      )}
      {text3Visible && (
        <div>
        <TextField
        id="textThree"
        label="Metin Girin"
        variant="standard"
        value={text2}
        onChange={handleChange}
        style={{ marginBottom: '20px', width: '80%' }}
     
      />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckbox === 'textThree'}
                onChange={handleCheckboxChange}
                name="textThree"
              />
            }
          
          />
        </div>
      )}
      {text4Visible && (
        <div>
        <TextField
        id="textFour"
        label="Metin Girin"
        variant="standard"
        value={text3}
        onChange={handleChange}
        style={{ marginBottom: '20px', width: '80%' }}
     
      />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckbox === 'textFour'}
                onChange={handleCheckboxChange}
                name="textFour"
              />
            }
          
          />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
  {textAreaVisible && (
    <>
            <div>
              <TextField
                id="textArea"
                label="Metin Girin"
                variant="standard"
                value={textArea}
                onChange={handleChange}
                multiline
                rows={4}
                InputProps={{
                  style: { marginBottom: '10px', width: '60%' },
                }}
                style={{ marginBottom: '10px', width: '300%' }}
              />
            </div>
            <div>
            {lineCounts.map((count, index) => (
              <p key={index}>Satır {index + 1}: {count} karakter</p>
            ))}
          </div>
            <div style={{marginBottom: '20px'}}>
              <ButtonGroup variant="outlined" aria-label="text alignment">
                <Button onClick={() => handleAlignmentChange('left')}>Sola Yatır</Button>
                <Button onClick={() => handleAlignmentChange('center')}>Ortala</Button>
                <Button onClick={() => handleAlignmentChange('right')}>Sağa Yatır</Button>
              </ButtonGroup>
            </div>
          </>
        )} 
        </div>
        {!textAreaVisible && ( <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{ marginBottom: '20px' }}
          >
            <Button id={'up'} onClick={handleButtonClick} variant="outlined" startIcon={<ArrowUpwardIcon />} style={{ marginBottom: '10px' }} />
            <Box display="flex">
              <Button id={'left'} onClick={handleButtonClick} variant="outlined" startIcon={<ArrowBackIcon />} style={{ marginRight: '10px' }} />
              <Button id={'right'} onClick={handleButtonClick} variant="outlined" startIcon={<ArrowForwardIcon />} style={{ marginLeft: '10px' }} />
            </Box>
            <Button id={'down'} onClick={handleButtonClick} variant="outlined" startIcon={<ArrowDownwardIcon />} style={{ marginTop: '10px' }} />
          </Box>)}
       
          <div>
       
          <CustomButton variant="outlined" onClick={handleClickOpen} style = {{marginRight: '10px'}}>
          SVG Indir
         </CustomButton>
          <CustomButton variant="outlined" onClick={trySVG}>
            Resmi Dene
          </CustomButton>
          </div>
         

        </div>

        <div className="right-panel">
          {svgExample === '' && svgContent === '' ? (
            <div>
              <p>Lütfen Düzenlemek İstediğiniz Dizaynı Seçiniz.</p>
            </div>
          ) : (
            <>
              {svgExample !== '' ? (
                <>
                  <div dangerouslySetInnerHTML={{ __html: svgExample }} />
                  <svg
                    style={{ display: 'flex', alignItems: 'center', height: '500', justifyContent: 'center' }}
                    width="500"
                    dangerouslySetInnerHTML={{ __html: svgExample }}
                  />
                </>
              ) : (
                <>
                  <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                  <svg
                    style={{ display: 'flex', alignItems: 'center', height: '500', justifyContent: 'center' }}
                    width="500"
                    dangerouslySetInnerHTML={{ __html: svgContent }}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Download SVG</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a name for the file.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="File Name"
            type="text"
            fullWidth
            variant="outlined"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={downloadSVG} variant="contained" color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>

    
  );
};

export default SvgConverter;